<template>
  <v-container>
    <v-row>
      <div id="ticketingMap" />
      <v-col>
        <v-card class="mt-4 mx-auto">
          <v-overlay :value="overlay">
            <v-progress-circular
              indeterminate
              size="64"
            />
          </v-overlay>
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="success"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-card
              dark
              color="color_green"
            >
              <v-card-title>Insert request for Customer</v-card-title>
            </v-card>
          </v-sheet>
          <v-form
            v-model="valid"
            lazy-validation
          >
            <v-card-text class="mt-2">
              <span class="ticketing-card-subtitle">
                <b>Insert request for</b>
              </span>
              <v-row>
                <v-col
                  cols="6"
                  class="pb-2"
                >
                  <v-autocomplete
                    v-model="selectedCustomer"
                    :items="services.linkedCustomers"
                    label="Customer"
                    prepend-icon="mdi-account-circle-outline"
                    item-text="ragione_sociale"
                    return-object
                    @change="storeAppUsers(selectedCustomer.crm_id);"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pb-2"
                >
                  <v-autocomplete
                    v-model="selectedAppUser"
                    :items="app_user.aUsers"
                    label="App user"
                    prepend-icon="mdi-account"
                    item-text="username"
                    :disabled="app_user.aUsers.length == 0 || selectedCustomer.limit_reached"
                    return-object
                    @change="getAppUserPolicy()"
                  />
                </v-col>
              </v-row>
              <span class="ticketing-card-subtitle">
                <b>Request features</b>
              </span>
              <v-row>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-select
                    v-model="selectedDocumentTypes"
                    :items="documentTypesList"
                    item-text="document_type"
                    label="Document types"
                    prepend-icon="mdi-file-document-edit-outline"
                    return-object
                    @change="clearLocationItems()"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-select
                    v-model="selectedMeteoTypes"
                    :items="meteoTypesList"
                    item-text="name"
                    label="Meteo types"
                    prepend-icon="mdi-cloud-outline"
                    return-object
                    @change="updateMeteoType()"
                  />
                </v-col>
                <v-col
                  class="pb-0"
                >
                  <v-menu
                    ref="rangeMenu"
                    v-model="rangeMenu"
                    :close-on-content-click="false"
                    :return-value.sync="rangeDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="rangeDateText"
                        multiple
                        chips
                        small-chips
                        label="Range date"
                        prepend-icon="mdi-calendar-range-outline"
                        readonly
                        :disabled="Object.keys(selectedMeteoTypes).length === 0"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="rangeDate"
                      no-title
                      range
                      scrollable
                      first-day-of-week="1"
                      :min="rangeDate[0]"
                      :max="getCalendarMaxDate()"
                    >
                      <div
                        class="d-flex flex-column mx-auto"
                      >
                        <div class="d-flex justify-space-between">
                          <v-btn
                            text
                            color="red darken-2"
                            @click="closeDateRange()"
                          >
                            Reset
                          </v-btn>
                          <v-btn
                            text
                            color="success"
                            :disabled="rangeDate.length < 2"
                            @click="$refs.rangeMenu.save(rangeDate)"
                          >
                            OK
                          </v-btn>
                        </div>
                      </div>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <div class="mt-4 ticketing-card-subtitle">
                <b>Geographical references</b>
              </div>
              <div>
                <v-radio-group
                  v-model="selectedAddressType"
                  row
                  mandatory
                  @change="resetAddress()"
                >
                  <v-radio
                    label="Address"
                    :ripple="true"
                    value="Address"
                    color="#0da344"
                  />
                  <v-radio
                    v-if="isPuntuale || isRelazione"
                    label="Manual coordinates"
                    :ripple="true"
                    value="Manual"
                    color="#0da344"
                    @click="isManualCoordinates = true"
                  />
                </v-radio-group>
                <v-row v-if="selectedAddressType === 'Address'">
                  <v-col class="py-8">
                    <v-autocomplete
                      v-if="isComunale || isProvinciale"
                      v-model="selectedAddressFromSelect"
                      class="ma-0"
                      :items="ticketing.comuniProvince"
                      label="Seleziona un comune o una provincia"
                      :disabled="Object.keys(selectedMeteoTypes).length === 0"
                      @change="getCoordinatesFromAddress()"
                    />
                    <input
                      v-else
                      ref="autocomplete"
                      placeholder="Search on Google Maps"
                      class="search-location"
                      :disabled="Object.keys(selectedMeteoTypes).length === 0"
                      onfocus="value = ''"
                      type="text"
                      @click="autocompleteInit()"
                    >
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="6">
                    <v-text-field
                      id="lat"
                      v-model="latitude"
                      label="Latitude"
                      :rules="number_rules"
                      @keyup="map.address = ''; map.addressProv = ''"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      id="lng"
                      v-model="longitude"
                      label="Longitude"
                      :rules="number_rules"
                      @keyup="map.address = ''; map.addressProv = ''"
                    />
                  </v-col>
                </v-row>
                <v-text-field
                  v-if="!isComunale && !isProvinciale"
                  v-model="placeName"
                  label="Address"
                  :disabled="true"
                />
                <div class="d-flex flex-column align-start">
                  <v-btn
                    v-if="selectedAddressType === 'Manual'"
                    id="submit"
                    color="#0da344"
                    small
                    style="color:white"
                    :disabled="disableFindAddress"
                    @click="dispatchManualGeocoderMarker()"
                  >
                    Find Address
                  </v-btn>
                  <v-btn
                    color="#0da344"
                    small
                    style="color:white"
                    class="my-2"
                    :disabled="checkForm()"
                    @click="addLocation()"
                  >
                    Add location
                  </v-btn>
                </div>
                <v-data-table
                  :headers="ticketing.ticketingRequestHeaders"
                  :items="locationItems"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <delete
                      :id="item.name"
                      @deleted="deleteItem(item)"
                    />
                  </template>
                  <template
                    v-slot:[`item.start_date`]="{ item }"
                  >
                    <span>
                      {{ item.start_date ? moment(item.start_date).format('DD-MM-YYYY') : item.start_date }}
                    </span>
                  </template>
                  <template
                    v-slot:[`item.end_date`]="{ item }"
                  >
                    <span>
                      {{ item.end_date ? moment(item.end_date).format('DD-MM-YYYY') : item.end_date }}
                    </span>
                  </template>
                  <template
                    v-slot:[`item.latitude`]="{ item }"
                  >
                    <map-coordinate
                      :coordinate="item.latitude"
                      :type="item.meteo_type"
                    />
                  </template>
                  <template
                    v-slot:[`item.longitude`]="{ item }"
                  >
                    <map-coordinate
                      :coordinate="item.longitude"
                      :type="item.meteo_type"
                    />
                  </template>
                  <template v-slot:[`item.meteo_type`]="{ item }">
                    <split-document-or-meteo-type :type="item.meteo_type" />
                  </template>
                </v-data-table>
                <ticketing-priority-selection
                  v-if="checkRequestManual"
                  :evasion-limit="evasion_limit"
                  :location-items="locationItems"
                  :is-provinciale="isProvinciale"
                  :concurrent-request-count="concurrentRequestCount"
                  @update-evasion-limit="updateEvasionLimit($event)"
                  @can-be-moved="canBeMoved($event)"
                />
                <document-s3-table
                  v-if="app_user_ticket_id != ''"
                  :app-user-ticket-id="app_user_ticket_id"
                  :ticketing-path="true"
                  :request-type="selectedMeteoTypes.is_auto ? 'A' : 'M'"
                />
                <v-textarea
                  v-model="notes"
                  auto-grow
                  class="mt-2"
                  label="Note"
                  :counter="selectedMeteoTypes.is_auto ? 100 : 512"
                  :maxlength="selectedMeteoTypes.is_auto ? 100 : 512"
                />
                <v-alert
                  :value="alert"
                  color="red accent-4"
                  dark
                  transition="scale-transition"
                >
                  extension file not allowed, please insert <b>.pdf</b>
                </v-alert>
              </div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                color="#0da344"
                style="color:white"
                :disabled="confirmIsDisabled"
                @click="confirmRequest()"
              >
                Send request
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import GmapMap from "@/components/core/GmapMap.vue";
import { mapActions, mapState } from 'vuex';
import { getRegExp } from '@/formRules.js';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-business-days';

export default {
  components: {
    DocumentS3Table: () => import('@/components/utils/DocumentS3Table'),
    delete: () => import('@/components/utils/Delete'),
    SplitDocumentOrMeteoType: () => import('@/components/utils/SplitDocumentOrMeteoType'),
    TicketingPrioritySelection: () => import('@/components/services/TicketingPrioritySelection'),
    MapCoordinate: () => import('@/components/utils/MapCoordinate')
  },
  data() {
    return {
      dialog: true,
      alert: false,
      rangeMenu: false,
      valid: true,
      overlay: false,
      isManualCoordinates: false,
      requestCanBeMoved: false,
      autocomplete: null,
      newMap: null,
      country: '',
      latitude: '',
      longitude: '',
      selectedAddressFromSelect: '',
      selectedAddressType: 'Address',
      notes: '',
      node_name: '',
      app_user_ticket_id: '',
      service_id: '',
      evasion_limit: '',
      selectedMeteoTypesArray: [],
      custom_rules: [],
      number_rules: [...getRegExp('numbers_dot_only')],
      meteoTypes: [],
      rangeDate: [],
      selected: [],
      locationItems: [],
      selectedDocumentTypes: {},
      selectedMeteoTypes: {},
      selectedCustomer: {},
      selectedAppUser: {},
      requestTypes: {},
      documentTypesList: [],
      meteoTypesList: [],
      ticket_id: ''
    };
  },
  computed: {
    ...mapState(['policy', 'ticketing', 'file_transfer', 'app_user', 'map', 'services', 'policy']),
    rangeDateText() {
      const start_date = this.rangeDate[0] ? `${moment(this.rangeDate[0]).format('DD-MM-YYYY')},` : '';
      const end_date = this.rangeDate[1] ? `${moment(this.rangeDate[1]).format('DD-MM-YYYY')}` : '';

      return `${start_date} ${end_date}`;
    },
    isRelazione() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.selectedDocumentTypes.document_type.toLowerCase().includes('relazione');
    },
    isPuntuale() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.selectedDocumentTypes.document_type.toLowerCase().includes('puntuale');
    },
    isProvinciale() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.selectedDocumentTypes.document_type.toLowerCase().includes('provinciale');
    },
    isComunale() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.selectedDocumentTypes.document_type.toLowerCase().includes('comunale');
    },
    isReport() {
      return Object.keys(this.selectedDocumentTypes).length > 0 && this.selectedDocumentTypes.document_type.toLowerCase().includes('report');
    },
    isLocationItemsSelected() {
      return this.locationItems.length > 0;
    },
    checkRequestManual() {
      // return (this.isReport && !this.selectedMeteoTypes.is_auto) || this.checkRequestType() === 'M';
      return this.isReport && this.checkRequestType() === 'M';
    },
    confirmIsDisabled() {
      if (this.isLocationItemsSelected) {
        if (!this.selectedMeteoTypes.is_auto || this.checkRequestType() === 'M') {
          return this.isReport ? !this.evasion_limit : false;
        }

        return false;
      }

      return true;
    },
    placeName() {
      return this.isProvinciale ? this.map.addressProv : this.map.address;
    },
    disableFindAddress() {
      if (this.valid) {
        return this.selectedAddressType === 'Address' || (this.latitude === '' || this.longitude === '');
      }

      return !this.valid;
    },
    concurrentRequestCount() {
      const count = this.selectedMeteoTypesArray.filter(e => !e.is_auto).length;

      return this.isProvinciale ? 1 : count;
    }
  },
  watch: {
    $route() {},
    '$route.params': {
      handler(newValue) {
        this.service_id = newValue.id;
        this.getTicketingConfiguration(newValue.id);
      },
      immediate: true
    }
  },
  async mounted() {
    if (this.services.services.length == 0) {
      this.$router.push('/');
    }

    await this.storeCustomers();
  },
  methods: {
    ...mapActions(['listComuniProvinceAction', 'createTicketingRequestAction', 'linkLocationToTicketingRequestAction', 'linkDocumentS3ToTicketingRequestAction', 'ticketingReverseGeocoding', 'storeCustomers', 'storeAppUsers', 'storeGetTicketingServiceConfiguration', 'storeEnabledServicesPolicy', 'storeToastMessage']),
    checkForm() {
      let requiredParams = this.valid && this.rangeDate.length > 0;
      requiredParams = requiredParams && this.latitude !== '' && this.longitude !== '';
      requiredParams = requiredParams && Object.keys(this.selectedDocumentTypes).length !== 0 && Object.keys(this.selectedMeteoTypes).length !== 0;
      requiredParams = requiredParams && Object.keys(this.selectedAppUser).length !== 0;

      requiredParams = this.isComunale || this.isProvinciale
        ? requiredParams && this.selectedAddressFromSelect !== ''
        : requiredParams && !this.isManualCoordinates && this.placeName !== '';

      return !requiredParams;
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.$refs.rangeMenu.save([]);
    },
    getCoordinatesFromAddress() {
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({ address: this.selectedAddressFromSelect, language: 'it' })
        .then((response) => {
          const location = response.results[0].geometry.location;
          this.latitude = location.lat();
          this.longitude = location.lng();

          if (this.isProvinciale) {
            this.map.addressProv = this.selectedAddressFromSelect;
          } else {
            this.map.address = this.selectedAddressFromSelect;
          }
        })
        .catch((e) => {
          if (e) {
            this.storeToastMessage({
              text: 'Address not found' + e,
              type: 'error'
            });
          }
        });
    },
    autocompleteInit() {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        { types: ['geocode'] }
      );

      this.autocomplete.addListener('place_changed', () => {
        const googlePlace = this.autocomplete.getPlace();
        let place = '';

        googlePlace.address_components.forEach(item => {
          if (item.types.includes('country')) {
            this.country = item.short_name;
          }

          if (this.isProvinciale && item.types[0] === 'administrative_area_level_2') {
            place = `${item.long_name.replace('Città Metropolitana di ', '').replace('Provincia di ', '')} (${item.short_name})`;
          } else {
            if (item.types[0] === 'administrative_area_level_3') {
              place += item.short_name;
            }

            if (place !== '' && item.types[0] === 'administrative_area_level_2') {
              place += ` (${item.short_name})`;
            }
          }
        });

        if (place === '') {
          place = googlePlace.formatted_address;
        }

        this.map.ticketing_formatted_address = googlePlace.formatted_address;
        this.map.address = place || 'SNC';
        this.map.addressProv = place || 'SNC';
        this.latitude = googlePlace.geometry.location.lat();
        this.longitude = googlePlace.geometry.location.lng();
      });
    },
    resetAddress() {
      this.map.address = '';
      this.map.addressProv = '';
      this.latitude = '';
      this.longitude = '';
      this.isManualCoordinates = false;
    },
    dispatchManualGeocoderMarker() {
      this.ticketingReverseGeocoding([this.longitude, this.latitude]);
      this.isManualCoordinates = false;
    },
    addLocation() {
      const startDate = this.rangeDate[0];
      const endDate = this.rangeDate[1];

      this.locationItems.push({
        latitude: this.latitude,
        longitude: this.longitude,
        meteo_type: `${this.selectedMeteoTypes.name}, ServiceCard.${this.ticket_id}.${this.selectedMeteoTypes.meteo_id}.meteoType`,
        ticketing_formatted_address: this.map.ticketing_formatted_address,
        address: this.placeName,
        country: this.country,
        start_date: startDate,
        end_date: endDate
      });

      this.updateMeteoTypesArray();

      this.map.address = '';
      this.map.addressProv = '';
      this.evasion_limit = '';
      this.selectedAddressFromSelect = '';
    },
    updateMeteoType() {
      if (this.isProvinciale) {
        this.locationItems.forEach(item => {
          item.meteo_type = `${this.selectedMeteoTypes.name}, ServiceCard.${this.ticket_id}.${this.selectedMeteoTypes.meteo_id}.meteoType`;
        });
      }
    },
    updateMeteoTypesArray() {
      const alreadyAdded = this.selectedMeteoTypesArray.some(e => e.meteo_id === this.selectedMeteoTypes.meteo_id);

      if (!alreadyAdded) {
        this.selectedMeteoTypesArray.push(this.selectedMeteoTypes);
      }
    },
    deleteItem(item) {
      this.locationItems.splice(this.locationItems.indexOf(item), 1);
      this.selectedMeteoTypesArray.splice(this.selectedMeteoTypesArray.indexOf(item), 1);
    },
    updateMeteoTypeList() {
      this.ticketing.ticketingServiceConfiguration.config.forEach(element => {
        if (this.selectedDocumentTypes.document_type === element.document_type) {
          this.selectedMeteoTypes = {};
          this.meteoTypesList = [];
          element.meteo_types?.forEach(item => {
            this.meteoTypesList.push(item);
          });
        }
      });
    },
    async clearLocationItems() {
      if (this.isProvinciale || this.isComunale) {
        this.overlay = true;
        await this.listComuniProvinceAction(this.isProvinciale);
        this.overlay = false;
      }

      this.updateMeteoTypeList();
      this.rangeDate = [];
      this.evasion_limit = '';
      this.locationItems = [];
      this.selectedMeteoTypesArray = [];
      this.selectedAddressFromSelect = '';
      this.resetAddress();

      const checkTimeRange = (time) => {
        const [start, end] = time;
        const startDate = new Date(start).getTime();
        const endDate = new Date(end).getTime();
        const result = (endDate - startDate) / (1000 * 3600 * 24);

        return result > 90;
      };

      if (!this.isRelazione && this.rangeDate.length > 1) {
        if (checkTimeRange(this.rangeDate)) {
          this.rangeDate = [];
        }
      }
    },
    async getTicketingConfiguration(service_id) {
      this.overlay = true;
      await this.storeGetTicketingServiceConfiguration(service_id);
      this.overlay = false;

      this.ticket_id = this.ticketing.ticketingServiceConfiguration.ticket_id;

      if (this.ticketing.ticketingServiceConfiguration.config?.length > 0) {
        this.documentTypesList = JSON.parse(JSON.stringify(this.ticketing.ticketingServiceConfiguration.config));
      }
    },
    async getAppUserPolicy() {
      const data = {
        app_user_id: this.selectedAppUser.app_user_id,
        service_type: 'TICKET'
      };
      await this.storeEnabledServicesPolicy(data);

      if (this.policy.enabledServicesPolicy.TICKET.permissions.length > 0) {
        this.node_name = this.policy.enabledServicesPolicy.TICKET.permissions[0].node_name;
      }

      this.app_user_ticket_id = this.policy.enabledServicesPolicy.TICKET.enabled_service[0].app_user_ticket_id;
    },
    checkRequestType(locations) {
      let requestType = '';
      const locationItems = [];

      if (locations) {
        const config = this.ticketing.ticketingServiceConfiguration.config.find(e => e.document_id === this.selectedDocumentTypes.document_id);
        const meteoType = config.meteo_types.find(e => e.meteo_id === locations[0].meteo_type.split('.')[2]);

        requestType = !meteoType.is_auto ? 'M' : 'A';
        locationItems.push(...locations);
      } else {
        const checkMultipleMeteoTypes = this.selectedMeteoTypesArray.some(e => !e.is_auto) ? 'M' : 'A';
        const checkSingleMeteoTypes = this.selectedMeteoTypes.is_auto ? 'A' : 'M';

        requestType = this.isProvinciale ? checkSingleMeteoTypes : checkMultipleMeteoTypes;
        locationItems.push(...this.locationItems);
      }

      const date_limit = this.ticketing.ticketingServiceConfiguration.date_limit;
      const toBeManual = locationItems.some(e => {
        if (!this.isComunale && !this.isProvinciale) {
          const isOutsideItaly = !e.ticketing_formatted_address?.includes('Italia') && !e.ticketing_formatted_address?.includes('Italy') && !e.ticketing_formatted_address?.includes('Italien');
          // const isOutsideItaly = e.country !== 'IT';

          return e.start_date < date_limit || e.address === 'SNC' || isOutsideItaly;
        }

        return e.start_date < date_limit || e.address === 'SNC';
      });

      return toBeManual ? 'M' : requestType;
    },
    groupByMeteoType(array) {
      const gruppi = array.reduce((acc, item) => {
        const meteo_type = item.meteo_type;

        if (!acc[meteo_type]) {
          acc[meteo_type] = [];
        }

        acc[meteo_type].push(item);

        return acc;
      }, {});

      return Object.values(gruppi);
    },
    async confirmRequest() {
      const locationData = this.groupByMeteoType(this.locationItems);

      for await (const [index, location] of locationData.entries()) {
        if (index > 0) {
          this.ticketing.request_id = uuidv4();
        }

        await this.elaborateRequest(location);
      }

      this.$router.push({
        name: 'TicketingRequestList',
        params: { id: this.service_id }
      });
    },
    async elaborateRequest(locationData) {
      const translatedDocumentType = `${this.selectedDocumentTypes.document_type}, ServiceCard.${this.ticket_id}.${this.selectedDocumentTypes.document_id}.documentType`;

      locationData.forEach(location => {
        location.app_user_ticket_id = this.app_user_ticket_id,
        location.request_id = this.ticketing.request_id;
        location.location_id = uuidv4();
      });

      const single_notification = this.policy.enabledServicesPolicy.TICKET.permissions.some(item => item.permission_name === 'Invio notifica al singolo utente');
      const group_notification = this.policy.enabledServicesPolicy.TICKET.permissions.some(item => item.permission_name === 'Invio notifica di gruppo');
      const send_notification = single_notification || group_notification;

      const requestData = {
        app_user_ticket_id: this.app_user_ticket_id,
        request_id: this.ticketing.request_id,
        request_type: this.checkRequestType(locationData),
        document_type: translatedDocumentType,
        username: this.selectedAppUser.username,
        evasion_date: '',
        evasion_limit: this.checkRequestType(locationData) === 'M' ? this.evasion_limit : '',
        request_date: moment().format('YYYY-MM-DD'),
        request_state: 'SENT',
        notes: this.notes || '',
        node_name: this.node_name,
        surname: this.selectedAppUser.surname,
        email: this.selectedAppUser.email,
        phone: this.selectedAppUser.phone,
        crm_id: this.selectedCustomer.crm_id,
        ragione_sociale: this.selectedCustomer.ragione_sociale,
        _name: this.selectedAppUser._name,
        locations: [...locationData],
        send_email: send_notification,
        group_notification: group_notification,
        can_be_moved: this.requestCanBeMoved
      };

      requestData.locations.forEach(element => {
        delete element.ticketing_formatted_address;
        delete element.country;
      });

      this.overlay = true;
      await this.createTicketingRequestAction(requestData);

      if (this.ticketing.createRequestState) {
        await this.linkLocationToTicketingRequestAction(locationData);

        if (this.ticketing.documentS3ItemsRadarmeteo.length > 0) {
          const documents = this.ticketing.documentS3ItemsRadarmeteo.map(e => {
            return {
              ...e,
              request_id: requestData.request_id,
              request_type: requestData.request_type,
              document_s3_id: uuidv4()
            };
          });
          await this.linkDocumentS3ToTicketingRequestAction(documents);
        }
      }

      this.ticketing.documentS3ItemsRadarmeteo = [];
      this.overlay = false;
    },
    getCalendarMaxDate() {
      return moment().format('YYYY-MM-DD');

      // const todayDate = moment();
      // let limitDate;

      // if (!this.isRelazione && this.selectedMeteoTypes.is_auto) {
      //   const hour = this.isPuntuale ? 13 : 18;
      //   const referenceTime = moment().set('hour', hour).set('minute', 0).set('second', 0);

      //   const days = referenceTime && moment().isBefore(referenceTime) ? 3 : 2;
      //   limitDate = moment(todayDate).subtract(days, 'days');
      // } else {
      //   limitDate = todayDate;
      // }

      // return moment(limitDate).format('YYYY-MM-DD');
    },
    canBeMoved(prevFreeSlots) {
      this.requestCanBeMoved = !this.isProvinciale && !this.isRelazione && !prevFreeSlots;
    },
    updateEvasionLimit(value) {
      this.evasion_limit = value;
    }
  }
};
</script>

<style scoped>
.v-card-scroll {
  overflow-y: scroll;
}
.ticketing-card-title {
  color: #0da344;
  background-color: #f4f3f3;
  margin-top: 1%;
  font-size: 22px;
}
.ticketing-card-subtitle {
  font-size: 16px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 16px;
}
input[type="text"] {
    font-size:16px;
}
</style>

<style>
.search-location {
  width: 100%;
  border: 0;
  margin-top:0.5%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.40);
}
.search-location:focus {
  outline: none;
}
</style>
