const regExps = {
  email: [
    v =>
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-_]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-_]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        v
      ) || 'E-mail must be valid'
  ],
  numbers_only: [v => /^\d+$/.test(v) || 'Numbers only allowed'],
  numbers_dot_only: [v => /^(?:\d+(\.\d+)?|)$/.test(v) || 'Formato coordinate non valido. Utilizzare il formato decimale con il punto come separatore (es. lat: 41.8924 lon: 12.4853).'],
  no_uppercase: [v => /^[^A-Z]+$/.test(v) || 'Uppercase not allowed'],
  space_not_allowed: [v => /^\S+$/.test(v) || 'Space not allowed'],
  phone_number: [
    v => {
      if (v === '') {
        return true; // Il campo è vuoto, quindi è valido
      } else {
        return /^(\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5}|\(?\d{2,6}\)?)(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/.test(v) || 'please enter a valid phone number';
      }
    }
  ],
  fiscal_code: [
    v =>
      /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i.test(
        v
      ) || 'please enter a valid fiscal code'
  ],
  hex_code: [v => /^$|^#([0-9A-Fa-f]{3}){1,2}$/i.test(v) || 'Please insert a valid hex code'],
  positive_numbers: [v => (!isNaN(parseFloat(v)) && v >= 0) || 'Slots can\'t be less than 0'],
  year_nullable: [v => /^(null|(1|2)\d{3})$/i.test(v) || 'Please insert a valid year']
  // vat_number: [
  //   v =>
  //     /^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$/.test(
  //       v
  //     ) || "please enter a valid vat number"
  // ]
};

export const required = [v => !!v || 'Field is required'];

export const manageLenghtError = maxLenght => [
  v =>
    (v && v.length <= maxLenght) ||
    `Field must be less than ${maxLenght} characters`
];

export const email_regexp = regExps.email;

export const getRegExp = rule => regExps[rule];

export const checkRegExp = (rule, value) => {
  const regExpFn = regExps[rule][0]; // Ottieni la funzione per la regola specificata
  const regExp = !(typeof regExpFn(value) === 'string'); // Esegui la funzione per ottenere la regex

  return regExp;
};
