<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mt-4 mx-auto">
          <v-overlay :value="overlay">
            <v-progress-circular
              indeterminate
              size="64"
            />
          </v-overlay>
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="success"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-card
              dark
              color="color_green"
            >
              <v-card-title> Video Courses </v-card-title>
            </v-card>
          </v-sheet>
          <v-card-text class="pt-0">
            <v-data-table
              id="video-table"
              :search="searchVideo"
              :headers="video_headers"
              :items="!editOrder ? video_tutorial.videoTutorial : sortedVideos"
              sort-by="sort_id"
              must-sort
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                  color="white"
                >
                  <v-text-field
                    v-model="searchVideo"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                  <div v-if="!editOrder">
                    <v-btn
                      color="primary"
                      :dark="!!video_tutorial.videoTutorial.length"
                      :disabled="!video_tutorial.videoTutorial.length"
                      @click="startReordering()"
                    >
                      Edit Order
                    </v-btn>
                    <v-btn
                      dark
                      color="color_green"
                      class="ml-2"
                      @click="newVideo()"
                    >
                      New Video
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      color="color_green"
                      dark
                      @click="saveNewOrder()"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      color="error"
                      class="ml-2"
                      dark
                      @click="editOrder = false;"
                    >
                      Cancel
                    </v-btn>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div v-if="!editOrder">
                  <v-icon
                    small
                    @click="editVideo(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    class="ml-2"
                    @click="openTranslation(item)"
                  >
                    mdi-google-translate
                  </v-icon>
                  <delete
                    :id="String(item.video_id)"
                    @deleted="removeVideo(item)"
                  />
                </div>
                <div v-else>
                  <v-icon @click="editOrderItemUp(item)">
                    mdi-arrow-up-drop-circle-outline
                  </v-icon>
                  <v-icon
                    class="ml-2"
                    @click="editOrderItemDown(item)"
                  >
                    mdi-arrow-down-drop-circle-outline
                  </v-icon>
                </div>
              </template>
              <template v-slot:no-data>
                <span color="primary">No data to show you</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card
          v-if="createNewVideo"
          class="mt-3"
        >
          <v-card-title v-if="!isEditing">
            New Video
          </v-card-title>
          <v-card-title v-else>
            Edit Video
          </v-card-title>
          <v-card-text>
            <v-form
              ref="videoForm"
              v-model="formIsValid"
              lazy-validation
            >
              <v-text-field
                v-model="videoForm.title"
                label="Video Title"
                required
                :rules="rules"
                :counter="255"
              />
              <v-textarea
                v-model="videoForm.description"
                auto-grow
                class="mt-2"
                label="Video description"
                :counter="512"
              />
              <v-text-field
                v-model="videoForm.url"
                label="Video Url"
                class="mt-2"
                required
                :rules="rules"
                :counter="255"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :dark="!checkForm()"
              color="color_green"
              :disabled="checkForm()"
              @click="createVideo()"
            >
              <span v-if="!isEditing">Create Video</span>
              <span v-else>Save Edit</span>
            </v-btn>
            <v-btn
              dark
              color="error"
              class="ml-4"
              @click="createNewVideo = false; isEditing = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else-if="isTranslating"
          class="mt-3"
        >
          <v-card-title>
            <v-col class="col-4">
              Translations:
            </v-col>
            <v-col>
              <v-select
                v-model="selectedLanguage"
                label="Select Language"
                :items="setLanguages"
                :value="setLanguages.text"
                @change="changeSelectedLanguage()"
              />
            </v-col>
          </v-card-title>
          <v-card-text>
            <div v-if="selectedLanguage">
              <v-card class="fill-height">
                <v-overlay :value="overlay">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  />
                </v-overlay>
                <v-card-text>
                  <h2>Title</h2>
                  <v-text-field
                    v-model="titleTranslation"
                    label="Title Translation"
                    required
                    :counter="255"
                    @keyup="addIsDisabled = false"
                  />
                  <h2>Description</h2>
                  <v-textarea
                    v-model="descriptionTranslation"
                    label="Description Translation"
                    required
                    :counter="500"
                  />
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <small
              v-if="showMessage"
              class="success--text"
            >
              <b>Translation saved.</b>
            </small>
            <v-btn
              dark
              color="error"
              @click="closeTranslation()"
            >
              Close
            </v-btn>
            <v-btn
              :dark="!checkTranslasionForm()"
              color="color_green"
              :disabled="checkTranslasionForm()"
              @click="saveTranslation()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, manageLenghtError } from '@/formRules.js';
import { mapState, mapActions } from 'vuex';
import { LANGUAGES } from '@/constants.js';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },
  data() {
    return {
      searchVideo: '',
      selectedLanguage: '',
      titleTranslation: '',
      descriptionTranslation: '',
      overlay: false,
      editOrder: false,
      createNewVideo: false,
      formIsValid: false,
      isEditing: false,
      isTranslating: false,
      showMessage: false,
      rules: [...required, ...manageLenghtError(255)],
      sortedVideos: [],
      video_headers: [
        {
          text: 'Order',
          value: 'sort_id',
          width: 90
        },
        {
          text: 'Title',
          value: 'title',
          sortable: false
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false
        },
        {
          text: 'Url',
          value: 'url',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: 120
        }
      ],
      videoForm: {
        title: '',
        description: '',
        url: '',
        sort_id: 0
      },
      translationObject: {}
    };
  },
  computed: {
    ...mapState(['video_tutorial']),
    setLanguages() {
      return LANGUAGES;
    }
  },
  async mounted() {
    await this.storeListVideoTutorial();
  },
  methods: {
    ...mapActions(['storeTranslationVideoTutorial', 'createEditTranslationAction', 'storeListVideoTutorial', 'createVideoTutorialAction', 'editVideoTutorialAction', 'editVideoTutorialSortingAction', 'deleteVideoTutorialAction']),
    startReordering() {
      this.sortedVideos = this.video_tutorial.videoTutorial.map(video => ({ ...video }));
      this.editOrder = true;
      this.isTranslating = false;
      this.isEditing = false;
      this.createNewVideo = false;
    },
    async saveNewOrder() {
      this.overlay = true;
      await this.editVideoTutorialSortingAction(this.sortedVideos);
      await this.storeListVideoTutorial();
      this.editOrder = false;
      this.overlay = false;
    },
    editOrderItemUp(item) {
      const index = this.sortedVideos.findIndex(v => v.video_id === item.video_id);

      if (index > 0) {
        this.swapOrder(index, index - 1);
      }
    },
    editOrderItemDown(item) {
      const index = this.sortedVideos.findIndex(v => v.video_id === item.video_id);

      if (index < this.sortedVideos.length - 1) {
        this.swapOrder(index, index + 1);
      }
    },
    swapOrder(index1, index2) {
      // Scambia l'ordine tra due elementi
      [this.sortedVideos[index1].sort_id, this.sortedVideos[index2].sort_id] =
      [this.sortedVideos[index2].sort_id, this.sortedVideos[index1].sort_id];

      this.sortedVideos = [...this.sortedVideos].sort((a, b) => a.sort_id - b.sort_id);
    },
    async removeVideo(item) {
      this.overlay = true;
      await this.deleteVideoTutorialAction(item.video_id);
      await this.storeListVideoTutorial();
      this.overlay = false;
    },
    newVideo() {
      this.createNewVideo = true;
      this.isEditing = false;
      this.emptyVideoForm();
    },
    editVideo(item) {
      this.isTranslating = false;
      this.createNewVideo = true;
      this.isEditing = true;
      this.videoForm = { ...item };
    },
    checkForm() {
      return !(this.videoForm.title !== '' &&
        this.videoForm.url !== '');
    },
    async createVideo() {
      this.overlay = true;

      if (this.isEditing) {
        await this.editVideoTutorialAction(this.videoForm);
        this.isEditing = false;
      } else {
        const newVideo = {
          ...this.videoForm,
          sort_id: this.video_tutorial.videoTutorial.length + 1
        };
        await this.createVideoTutorialAction(newVideo);
      }

      await this.storeListVideoTutorial();
      this.createNewVideo = false;
      this.emptyVideoForm();
      this.overlay = false;
    },
    emptyVideoForm() {
      this.videoForm = {
        title: '',
        description: '',
        url: '',
        sort_id: 0
      };
    },
    async changeSelectedLanguage() {
      this.titleTranslation = '';
      this.descriptionTranslation = '';
      this.storeTranslationData(this.translationObject);
      this.showMessage = false;
    },
    async storeTranslationData(item) {
      this.overlay = true;

      const dataTitle = {
        label_code: `VideoCourses.${item.video_id}.title`,
        lang: this.selectedLanguage.split('-')[0]
      };
      await this.storeTranslationVideoTutorial(dataTitle);
      this.titleTranslation = this.video_tutorial.videoTutorialTranslation;

      const dataDescription = {
        label_code: `VideoCourses.${item.video_id}.description`,
        lang: this.selectedLanguage.split('-')[0]
      };
      await this.storeTranslationVideoTutorial(dataDescription);
      this.descriptionTranslation = this.video_tutorial.videoTutorialTranslation;

      this.overlay = false;
    },
    openTranslation(item) {
      this.createNewVideo = false;
      this.isEditing = false;
      this.isTranslating = true;
      this.selectedLanguage = '';
      this.translationObject = item;
    },
    checkTranslasionForm() {
      return !(this.titleTranslation !== '' &&
        this.descriptionTranslation !== '');
    },
    async saveTranslation() {
      this.overlay = true;
      const ID = this.translationObject.video_id;

      const titleTranslation = {
        label_code: 'VideoCourses.' + ID + '.title',
        label_value: this.titleTranslation,
        lang: this.selectedLanguage.split('-')[0]
      };
      const descriptionTranslation = {
        label_code: 'VideoCourses.' + ID + '.description',
        label_value: this.descriptionTranslation,
        lang: this.selectedLanguage.split('-')[0]
      };
      await this.createEditTranslationAction(titleTranslation);
      await this.createEditTranslationAction(descriptionTranslation);

      this.closeTranslation();
      this.overlay = false;
    },
    closeTranslation() {
      this.translationObject = {},
      this.titleTranslation = '',
      this.descriptionTranslation = '';
      this.showMessage = false;
      this.saveIsDisabled = true;
      this.selectedLanguage = '';
      this.isTranslating = false;
    }
  }
};
</script>
