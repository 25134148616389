import { listVideoTutorial, createVideoTutorial, editVideoTutorial, editVideoTutorialSorting, deleteVideoTutorial, getTranslation } from '@/api/api.js';

// --------------------- STATE -----------------------------

export const state = {
  videoTutorial: [],
  videoTutorialTranslation: ''
};

// --------------------- MUTATIONS ----------------------------

export const mutations = {
  PUSH_VIDEO_TUTORIAL(state, videos) {
    state.videoTutorial = [];

    if (videos.length > 0) {
      state.videoTutorial.push(...videos);
    }
  },
  GET_TRANSLATION_VIDEO_TUTORIAL(state, translationObject) {
    state.videoTutorialTranslation = [];

    if (translationObject.length > 0) {
      state.videoTutorialTranslation = translationObject[0].label_value;
    }
  }
};

export const actions = {
  async storeListVideoTutorial({ dispatch, commit }, data) {
    try {
      const video = await listVideoTutorial(data);
      commit('PUSH_VIDEO_TUTORIAL', video.data.listVideoTutorial);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeListVideoTutorial',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createVideoTutorialAction({ dispatch }, data) {
    try {
      await createVideoTutorial(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Video Tutorial Created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createVideoTutorialAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editVideoTutorialAction({ dispatch }, data) {
    try {
      await editVideoTutorial(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Video Tutorial Edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editVideoTutorialAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editVideoTutorialSortingAction({ dispatch }, data) {
    try {
      await editVideoTutorialSorting(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Video Tutorial Sorting Edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editVideoTutorialSortingAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteVideoTutorialAction({ dispatch }, data) {
    try {
      await deleteVideoTutorial(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Video Tutorial Deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteVideoTutorialAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeTranslationVideoTutorial({ dispatch, commit }, data) {
    try {
      const resp = await getTranslation(data);
      commit('GET_TRANSLATION_VIDEO_TUTORIAL', resp.data.getTranslation);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeTranslationVideoTutorial',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
